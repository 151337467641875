const text: any = {
  ui: {
    learnMore: 'Learn more',
    stepworksStudio: 'Stepworks Studio',
  },
  navbar: {
    file: {
      new: 'New',
      open: 'Open...',
      openSheets: 'Open From Google Sheets...',
      save: 'Save',
      share: 'Share...',
      delete: 'Delete...',
      importStory: 'Import Story...',
      exportStory: 'Export Story...',
      connectApp: 'Connect to App...',
      upgrade: 'Upgrade...',
      benefits: 'Your Benefits...',
    },
  },
  about: {
    title: 'About',
    logoAlt: 'Stepworks Logo',
  },
  getCode: {
    title: 'Stepworks Studio',
    loading: 'Loading...',
    authenticating: 'Authenticating...',
    notLinked:
      'You’ll need to link your Patreon account to access this benefit.',
    patreonLinkButton: 'Link Patreon account',
    basicBenefits:
      'Thanks for being a supporter! Your Patreon account is linked, but you’ll need to contribute at a higher level to access this benefit.',
    upgradeAccessButton: 'Upgrade your access',
    enterCode:
      'Enter this code in the app you want to connect to your Stepworks Studio account:',
  },
  stories: {
    loading: 'Loading stories...',
  },
  activate: {
    desktopInstructions:
      'A browser window should have opened containing an activation code for this app. Enter it below.',
    vrInstructions:
      'A browser window should have opened containing an activation code for this app. Remove your headset, note the code, then put your headset back on and enter the code below.',
    placeholder: 'Enter activation code...',
    activationSuccess: 'Activation successful.',
    activationFailure: 'Activation failed. Please try again later.',
    submit: 'Submit code',
  },
  benefits: {
    nonSupporterTitle: 'Upgrade Stepworks Studio',
    nonSupporterHead: 'Become a supporter',
    nonSupporterDescription:
      'Stepworks Studio is made by Opertoon, an independent studio that’s been finding musical ways to make stories playable for over 15 years. By contributing on Patreon, you can unlock additional capacity and features in Stepworks Studio, and support Opertoon in making new creative work.',
    patreonLinkButton: 'Link your Patreon account',
    upgradeAccessButton: 'Upgrade your access',
    supporterTitle: 'Your Benefits',
    noContributionDescription:
      'Through your contribution on Patreon, you can unlock additional capacity and features in Stepworks Studio, and support Opertoon in making new creative work.',
    supporterHead: 'Thank you!',
    supporterDescription:
      'Thanks for being a supporter of Opertoon. Your Stepworks Studio benefits shown below are now active. Be sure to visit the Opertoon page on Patreon to make the most of your benefits!',
    accessBenefitsButton: 'Go to Patreon',
    currentLevel: 'Current level',
    storiesSaved: 'stories saved',
    mediaUploaded: 'MB media uploaded',
    tiers: {
      basic: {
        title: 'Standard Time',
        price: 'Free',
        description:
          'Create all kinds of things, from visual poetry to comics to musical instruments, and share them with the world.',
        benefits: ['Save 25 stories', 'Upload 10 MB of media'],
      },
      hobbyist: {
        title: 'Swing',
        price: '$5',
        description:
          'Expand the capacity of your Stepworks Studio account.',
        benefits: [
          'Save 50 stories',
          'Upload 100 MB of media',
        ],
      },
      professional: {
        title: 'Syncopation',
        price: '$10',
        description:
          'Maximize the capacity of your Stepworks Studio account.',
        benefits: [
          'Save unlimited stories',
          'Upload 500 MB of media',
        ],
      },
    },
  },
  connect: {
    title: 'Take Stepworks further.',
    otherApps:
      'You can bring your Stepworks Studios content into other apps, including a virtual movie theatre.',
    wantAppRewards:
      'And that by becoming a supporter on Patreon, you can get those apps as rewards?',
    haveAppRewards:
      'And that as a current Patreon supporter (thank you!), those apps are part of your rewards?',
    becomeSupporter: 'Become a supporter',
    linkPatreon: 'Link Patreon account',
    goToPatreon: 'Learn more about connecting apps',
    goToItch: 'Browse apps powered by Stepworks Studio',
  },
}
export default text
