import React from 'react'
import './App.css'
import './stepworks/Stepworks.css'
import { observer } from 'mobx-react'
import Stage from './stepworks/Stage.js'
import StateEditor from './StateEditor/StateEditor.js'
import EZCreateDialog from './EZCreateDialog.js'
import MediaPreview from './MediaPreview.js'
import MediaControls from './MediaControls.js'
import StorySettingsDialog from './StorySettingsDialog.js'
import SceneSettingsDialog from './SceneSettingsDialog.js'
//import BenefitsDialog from './dialogs/BenefitsDialog'
import SequenceSettingsDialog from './SequenceSettingsDialog.js'
import ColumnEditor from './ColumnEditor/ColumnEditor.js'
import CharacterDialog from './ColumnEditor/CharacterDialog.js'
import MusicPlayer from './stepworks/MusicPlayer.js'
import { Stepwise, Media, Step } from './stepworks/stepwise/stepwise-v2.js'
import { GoogleSheetsParser } from './stepworks/stepwise/utils/Sheets-es6.js'
import {
  Navbar,
  Button,
  Alignment,
  Popover,
  Card,
  FormGroup,
  Elevation,
  Menu,
  MenuItem,
  Position,
  Overlay,
  H1,
  Dialog,
  Alert,
  Classes,
  HTMLSelect,
  Intent,
  InputGroup,
  TextArea
} from '@blueprintjs/core'
//import Octokit from '@octokit/rest';
import Preload from 'image-preload'
import { v4 as uuidv4 } from 'uuid'
import { isMobile /*, isChrome*/ } from 'react-device-detect'
import ReactGA from 'react-ga'
import firebase from './firebase.js'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import text from './i18n/english'
import { isPropertySignature } from 'typescript'

/*
 * Stepworks Studio
 * © 2020-2025 Erik Loyer
 */

interface IProps {
  mode: string;
}

interface IState {
  benefits: number;
  currentDialog: string;
}

const App = observer(
  class App extends React.Component<IProps, IState> {
    version: string
    stageRef: React.RefObject<T>
    columnEditorRef: React.RefObject<T>

    constructor(props) {
      super(props)

      this.version = '2.0.3 build 1'

      this.benefitLevel = {
        basic: 0,
        hobbyist: 5,
        professional: 10
      }

      this.state = {
        user: null,
        userWasInvited: 'unknown',
        permissions: 'unknown',
        patreonAccountLinked: false,
        benefits: this.benefitLevel.basic,
        maxUploadedMedia: 10,
        maxStories: 25,
        stories: [],
        storiesById: {},
        storiesNeedUpdate: true,
        stepwise: null,
        flag: false,
        currentView: 'tab-preview',
        charactersEnabled: false,
        editSequence: null,
        editStep: null,
        editAction: null,
        editLocation: null,
        editCharacter: null,
        editMedia: null,
        editorBodyStyles: { marginTop: window.innerHeight + 'px' },
        selectedPanelId: null,
        currentDialog: 'none',
        gists: null,
        gistFileSelection: null,
        camera: null,
        isPreviewing: false,
        messageVisible: false,
        welcomeVisible: this.props.mode === 'play',
        sidebarVisible: false,
        expanded: false,
        charactersToIgnore: [],
        distributeOption: 'background-color',
        activationCode: null
      }
      this.stageRef = React.createRef()
      this.columnEditorRef = React.createRef()
      this.logout = this.logout.bind(this)
      this.loadStoryFromStepwiseJSON = this.loadStoryFromStepwiseJSON.bind(this)
      this.handleStepwiseEvent = this.handleStepwiseEvent.bind(this)
      this.sendStepwiseEvent = this.sendStepwiseEvent.bind(this)
      this.handleStepSelected = this.handleStepSelected.bind(this)
      this.handlePanelSelected = this.handlePanelSelected.bind(this)
      this.openGoogleSheet = this.openGoogleSheet.bind(this)
      this.handleTabChange = this.handleTabChange.bind(this)
      this.handleSceneSelected = this.handleSceneSelected.bind(this)
      this.handleSequenceSelected = this.handleSequenceSelected.bind(this)
      this.handleActionSelected = this.handleActionSelected.bind(this)
      this.handleCameraSelected = this.handleCameraSelected.bind(this)
      this.handleLocationSelected = this.handleLocationSelected.bind(this)
      this.handleMediaSelected = this.handleMediaSelected.bind(this)
      this.togglePreview = this.togglePreview.bind(this)
      this.handleKeyDown = this.handleKeyDown.bind(this)
      this.deleteMedia = this.deleteMedia.bind(this)
      this.importStory = this.importStory.bind(this)
      this.handleAudioLoaded = this.handleAudioLoaded.bind(this)
      this.handleCharacterSelected = this.handleCharacterSelected.bind(this)
      this.keyCodesToIgnore = [
        8, 9, 16, 17, 18, 20, 27, 32, 37, 39, 224, 91, 93
      ]
      this.preloadIndex = 0
      this.gotStoryFromUrl = false
      this.musicPlayer = React.createRef()
      this.characterDialogRef = React.createRef()
      this.stateEditor = React.createRef()
      this.distributeOptions = [
        { label: 'Also set background colors', value: 'background-color' },
        { label: 'Also set text colors', value: 'text-color' },
        { label: 'Arrange panels only', value: 'panels-only' }
      ]
    }

    setupAuth() {
      this.ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth())

      firebase.auth().onAuthStateChanged(
        (user) => {
          if (user) {
            // User is signed in.
            this.checkNewUser(user)
            this.getPermissionsForUser(user)
            this.getBenefitsForUser(user)
            this.loadDefaultStory()
            this.getStoriesForUser(user)
            this.getMediaForUser(user)
            user.getIdToken().then((accessToken) => {
              // do something
              if (!localStorage.hasLaunchedBefore) {
                this.openDialog('tutorial')
                localStorage.hasLaunchedBefore = true
              }
            })
            this.setState({
              user: user,
              storiesNeedUpdate: true
            })
            this.closeDialog()
          } else {
            // User is signed out.
            this.setState({
              user: null,
              permissions: 'unknown',
              stories: [],
              storiesById: {},
              storiesNeedUpdate: false
            })
            this.openDialog('sign-in')
          }
        },
        function (error) {
          console.log(error)
        }
      )
    }

    showSignInUI = () => {
      var uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return false
          },
          uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
            document.getElementById('loader').style.display = 'none'
          }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: 'edit',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        // Terms of service url.
        tosUrl: 'https://step.works/index.php/terms-conditions',
        // Privacy policy url.
        privacyPolicyUrl: 'https://step.works/index.php/privacy-policy'
      }
      this.ui.start('#firebaseui-auth-container', uiConfig)
    }

    checkNewUser = (user) => {
      const db = firebase.firestore()
      var docRef = db.collection('users').where('id', '==', user.uid)
      docRef
        .get()
        .then((doc) => {
          if (doc.empty) {
            db.collection('users')
              .add({
                id: user.uid,
                name: user.displayName,
                email: user.email
              })
              .then((docRef) => {
                console.log('User created with ID: ', docRef.id)
              })
              .catch((error) => {
                console.error('Error creating user: ', error)
              })
            this.createCRMContactForUser(user)
          }
        })
        .catch((error) => {
          console.log('Error getting user.')
        })
    }

    formatParams(params) {
      return (
        '?' +
        Object.keys(params)
          .map(function (key) {
            return key + '=' + encodeURIComponent(params[key])
          })
          .join('&')
      )
    }

    async createCRMContactForUser(user) {
      let temp = user.displayName.split(' ')
      let lastName = temp[temp.length - 1]
      let firstName = ''
      if (temp.length > 1) {
        temp.pop()
        firstName = temp.join(' ')
      }
      const properties = {
        email: user.email,
        firstname: firstName,
        lastname: lastName,
        contact_source: 'Stepworks Studio'
      }

      var xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            //console.log(xhr.responseText);
          } else {
            console.log('error creating contact')
          }
        }
      }
      xhr.open(
        'GET',
        'https://origin.step.works/createcontact' +
          this.formatParams(properties),
        true
      )
      xhr.send()
    }

    getBenefitsForSupportLevel = (cents) => {
      if (cents >= 1000) {
        return this.benefitLevel.professional
      } else if (cents >= 500) {
        return this.benefitLevel.hobbyist
      } else {
        return this.benefitLevel.basic
      }
    }

    getMaxStoriesForBenefits = (benefits) => {
      if (benefits === 10) {
        return 9999
      } else if (benefits === 5) {
        return 50
      } else if (benefits === 0) {
        return 25
      }
    }

    getMaxUploadedMediaForBenefits = (benefits) => {
      if (benefits === 10) {
        return 500
      } else if (benefits === 5) {
        return 100
      } else if (benefits === 0) {
        return 10
      }
    }

    getBenefitsForUser = (user) => {
      const self = this
      var xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const tier = JSON.parse(xhr.responseText)
            const benefits = self.getBenefitsForSupportLevel(
              tier.currently_entitled_amount_cents
            )
            const maxUploadedMedia =
              self.getMaxUploadedMediaForBenefits(benefits)
            const maxStories = self.getMaxStoriesForBenefits(benefits)
            self.setState({
              patreonAccountLinked: tier.account_linked,
              benefits: benefits,
              maxUploadedMedia: maxUploadedMedia,
              maxStories: maxStories
            })
          } else {
            console.log('error getting tier')
          }
        }
      }
      xhr.open(
        'GET',
        'https://origin.step.works/get_tier?user=' + user.uid,
        true
      )
      xhr.send()
    }

    getPermissionsForUser = (user) => {
      const db = firebase.firestore()

      // store user role
      db.collection('users')
        .where('id', '==', user.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let data = doc.data()
            if (data.role) {
              this.setState({ permissions: data.role })
            }
          })
        })
        .catch((error) => {
          console.log('Error getting user.')
        })

      var docRef = db.collection('invited').doc(user.email)
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({ userWasInvited: doc.data().invited ? 'yes' : 'no' })
          }
        })
        .catch((error) => {
          console.error('Error getting invitations.')
        })
    }

    loadDefaultStory() {
      this.loadStoryFromURL('data/empty-story.json')
    }

    getStoriesForUser = (user) => {
      if (this.state.storiesNeedUpdate) {
        const db = firebase.firestore()
        db.collection('stories')
          .where('author', '==', user.uid)
          .get()
          .then((querySnapshot) => {
            let stories = []
            let storiesById = {}
            querySnapshot.forEach((doc) => {
              let data = doc.data()
              stories.push({ key: doc.id, value: data })
              storiesById[doc.id] = data
            })
            stories.sort(function (a, b) {
              var nameA = a.value.title.toUpperCase()
              var nameB = b.value.title.toUpperCase()
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              return 0
            })
            this.setState({
              stories: stories,
              storiesById: storiesById,
              storiesNeedUpdate: false
            })
          })
          .catch((error) => {
            console.log('Error getting stories.')
          })
      }
    }

    getMediaForUser = (user) => {
      if (!user) user = this.state.user
      if (user) {
        const db = firebase.firestore()
        db.collection('media')
          .where('user', '==', user.uid)
          .get()
          .then((querySnapshot) => {
            let media = []
            let size = 0
            querySnapshot.forEach((doc) => {
              let data = doc.data()
              media.push({ key: doc.id, value: data })
              if (data.bytes) {
                size += data.bytes
              }
            })
            let sizeInMB = Math.round(size * 0.000001 * 100) * 0.01
            this.setState({
              uploadedMedia: media,
              uploadedMediaSize: sizeInMB
            })
            //console.log('Total uploaded media: ' + sizeInMB + 'MB')
          })
          .catch((error) => {
            console.log('Error getting stories.')
          })
      }
    }

    openEditor = () => {
      window.location.href = '/edit'
    }

    logout() {
      firebase.auth().signOut()
    }

    componentDidMount() {
      console.log('mode', this.props.mode)

      if (this.props.mode === 'edit') {
        this.setupAuth()
      }

      let searchParams = new URLSearchParams(this.props.location.search)

      // load default story or story from url
      let storyId = searchParams.get('story')
      if (storyId) {
        this.gotStoryFromUrl = true
        //this.getPublicGist(gistId);
        this.getStory(storyId)
      }

      window.addEventListener('resize', this.updateDimensions.bind(this))
      document.addEventListener('keydown', this.handleKeyDown, false)
    }

    handleKeyDown(e) {
      e = e || window.event
      switch (e.keyCode) {
        case 27: // esc
          if (this.state.isPreviewing && this.props.mode === 'edit') {
            this.togglePreview()
          }
          break
        case 32: // space
          if (this.state.isPreviewing) {
            this.stageRef.current.togglePlayPause()
          }
          break
        /*case 37: // left arrow
      if (this.state.isPreviewing) {
        this.stageRef.current.popFromHistory();
      }
      break;*/
        case 39: // right arrow
          if (
            this.state.isPreviewing &&
            this.state.stepwise.inputManager.enabled &&
            !this.stageRef.current.nextInHistory()
          ) {
            this.state.stepwise.nextStep()
          }
          break
        case 8: // delete
          if (this.props.mode === 'edit') {
            /*if (!this.state.isPreviewing) {
          if (this.columnEditorRef.current.state.currentColumn === 0) {
            this.openAlertDeleteStep();
          }
        } else*/ if (
              this.state.currentDialog === 'media-library' &&
              this.state.editMedia
            ) {
              if (document.activeElement.children.length > 0) {
                if (
                  document.activeElement.children[0].classList.contains(
                    'media-library'
                  )
                ) {
                  this.openDialog('delete-media')
                }
              }
            }
          }
          break
        default:
          break
      }
    }

    handleCameraSelected(camera) {
      this.setState({ camera: camera })
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions)
      document.removeEventListener('keydown', this.handleKeyDown, false)
    }

    loadStoryJSON(json) {
      if (this.state.stepwise) {
        this.state.stepwise.dispose()
      }
      var stepwise = new Stepwise({
        element: document.getElementsByClassName('stage-row')[0],
        audioContext: this.musicPlayer.current.midiSounds.audioContext,
        keyInput: true,
        clickInput: true,
        tapInput: true,
        gamepadInput: true,
        keyCodesToIgnore: this.keyCodesToIgnore
      })
      stepwise.inputManager.setEnabled(false)
      stepwise.eventManager.addListener(this.handleStepwiseEvent)
      stepwise.load(json, 'json')
      this.setState({
        stepwise: stepwise,
        editSequence: stepwise.score.currentScene.defaultSequence,
        editStep: stepwise.score.currentScene.defaultSequence.steps[0],
        editMedia: null,
        editAction: null,
        editCharacter: null,
        charactersEnabled: Object.values(stepwise.score.characters).length > 1
      })
      if (this.props.mode === 'play') {
        this.setPreview(true, true)
      }
      this.handleStoryUpdate(true)
      this.resetContent()
      stepwise.score.currentScene.defaultSequence.stepIndex = 0
      this.handleStepSelected(
        stepwise.score.currentScene.defaultSequence.steps[0]
      )
    }

    loadStoryFromURL(url) {
      if (this.state.stepwise) {
        this.state.stepwise.dispose()
      }
      var stepwise = new Stepwise({
        element: document.getElementsByClassName('stage-row')[0],
        audioContext: this.musicPlayer.current.midiSounds.audioContext,
        keyInput: true,
        clickInput: true,
        tapInput: true,
        gamepadInput: true,
        keyCodesToIgnore: this.keyCodesToIgnore
      })
      stepwise.inputManager.setEnabled(false)
      stepwise.eventManager.addListener(this.handleStepwiseEvent)
      var request = new Request(url)
      fetch(request)
        .then((response) => response.json())
        .then((json) => {
          let color = window.getRandomBackgroundColor()
          json.characters[0].color = color
          if (url === 'data/empty-story.json') {
            json.scenes[0].sequences[0].steps[0].states[0].backgroundColor =
              color
            let captionAlign = window.getRandomCaptionAlign(false)
            json.scenes[0].sequences[0].steps[0].states[1].align = captionAlign
            json.scenes[0].sequences[0].steps[0].states[1].textAlign =
              window.getTextAlignFromCaptionAlign(captionAlign)
          }
          stepwise.load(json, 'json')
          this.setState({
            stepwise: stepwise,
            expanded: false,
            editSequence: stepwise.score.currentScene.defaultSequence,
            editStep: stepwise.score.currentScene.defaultSequence.steps[0],
            editMedia: null,
            editAction: null,
            editCharacter: null,
            charactersEnabled:
              Object.values(stepwise.score.characters).length > 1
          })
          if (this.props.mode === 'play') {
            this.setPreview(true, true)
          }
          this.handleStoryUpdate(true)
          this.resetContent()
          stepwise.score.currentScene.defaultSequence.stepIndex = 0
          // this delay gives the editor highlight time to catch up
          setTimeout(
            () =>
              this.handleStepSelected(
                stepwise.score.currentScene.defaultSequence.steps[0]
              ),
            750
          )
        })
        .catch((error) => {
          console.error(error)
        })
    }

    newStory() {
      this.loadStoryFromURL('data/empty-story.json')
      this.setState({ currentStory: null })
    }

    userIsSupporter() {
      return (
        this.state.permissions === 'admin' ||
        this.state.benefits > this.benefitLevel.basic
      )
    }

    saveToCloud = () => {
      if (
        !this.userIsSupporter() &&
        !this.state.currentStory &&
        this.state.stories.length >= this.state.maxStories
      ) {
        this.openDialog('story-quantity-alert')
      } else {
        const db = firebase.firestore()
        if (!this.state.currentStory) {
          this.openDialog('save-story')
        } else {
          let storyRef = db.collection('stories').doc(this.state.currentStory)
          storyRef
            .set(
              {
                title: this.state.stepwise.score.title,
                story: this.state.stepwise.serialize()
              },
              { merge: true }
            )
            .then(() => {
              console.log('Story saved.')
              this.openDialog('confirm-save-alert')
              this.getStoriesForUser(this.state.user)
            })
            .catch((error) => {
              console.log('Error saving story: ', error)
            })
        }
      }
    }

    saveNewStoryToCloud = () => {
      const db = firebase.firestore()
      db.collection('stories')
        .add({
          title: this.state.stepwise.score.title,
          author: this.state.user.uid,
          story: this.state.stepwise.serialize()
        })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id)
          this.setState({ currentStory: docRef.id })
          this.openDialog('confirm-save-alert')
        })
        .catch((error) => {
          console.error('Error adding story: ', error)
        })
    }

    loadStoryFromStepwiseJSON(json) {
      // possibly redundant with the other json loader?
      if (this.state.stepwise) {
        this.state.stepwise.dispose()
      }
      var stepwise = new Stepwise({
        element: document.getElementsByClassName('stage-row')[0],
        audioContext: this.musicPlayer.current.midiSounds.audioContext,
        keyInput: true,
        clickInput: true,
        tapInput: false,
        gamepadInput: true,
        keyCodesToIgnore: this.keyCodesToIgnore
      })
      stepwise.inputManager.setEnabled(false)
      stepwise.eventManager.addListener(this.handleStepwiseEvent)
      stepwise.load(json, 'json')
      this.setState({
        stepwise: stepwise,
        editSequence: stepwise.score.currentScene.defaultSequence,
        editStep: stepwise.score.currentScene.defaultSequence.steps[0],
        charactersEnabled: Object.values(stepwise.score.characters).length > 1
      })
      this.handleStoryUpdate()
      this.resetContent()
      this.handleStepSelected(
        stepwise.score.currentScene.defaultSequence.steps[0]
      )
    }

    handleStepwiseEvent(type, obj) {
      switch (type) {
        case 'scoreLoaded':
          if (this.props.mode === 'play') {
            if (obj.analyticsId) {
              ReactGA.initialize(obj.analyticsId)
              ReactGA.pageview(
                window.location.pathname + window.location.search
              )
            }
          }
          break

        case 'action':
        case 'state':
          if (this.state.messageVisible) {
            this.setState({ messageVisible: false })
          }
          break

        case 'step':
          this.preloadByStep()
          if (this.props.mode === 'play') {
            if (this.state.stepwise.score.analyticsId) {
              ReactGA.event({
                category: 'Performance',
                action: 'Step',
                label:
                  this.state.stepwise.score.currentScene.currentSequence.title,
                value:
                  this.state.stepwise.score.currentScene.currentSequence
                    .stepIndex
              })
            }
          }
          break

        default:
          break
      }
    }

    preloadByStep() {
      let sequence = this.state.stepwise.score.currentScene.currentSequence
      if (sequence) {
        if (sequence.stepIndex === 0) {
          this.preloadIndex = 0
        }
        let maxPreloadIndex = Math.min(
          sequence.stepIndex + 3,
          sequence.steps.length - 1
        )
        while (this.preloadIndex <= maxPreloadIndex) {
          let media
          let step = sequence.steps[this.preloadIndex]
          /*step.states.forEach(state => {
          media = this.state.stepwise.score.getMedia(state.media);
          if (media) {
            if (state.type === 'image') {
              //console.log('preload ' + media.source + ' for step: ' + sequence.stepIndex + ' / ' + this.preloadIndex);
              Preload([media.source]/*, {onComplete: () => console.log('got image')}*);
            } else if (state.type === 'audio' && !isChrome) {
              //console.log('preload ' + media.source + ' for step: ' + sequence.stepIndex + ' / ' + this.preloadIndex);
              this.preloadAudio(media.source);
            }
          }
        });*/
          step.actions.forEach((action) => {
            media = this.state.stepwise.score.getMedia(action.content)
            if (media) {
              if (action.command === 'show-image') {
                //console.log('preload ' + media.source + ' for step: ' + sequence.stepIndex + ' / ' + this.preloadIndex);
                Preload(
                  [
                    media.source
                  ] /*, {onComplete: () => console.log('got image')}*/
                )
              }
            }
          })
          this.preloadIndex++
        }
      }
    }

    preloadAudio(url) {
      var audio = new Audio()
      audio.addEventListener('canplaythrough', this.handleAudioLoaded)
      audio.src = url
    }

    handleAudioLoaded(evt) {
      //console.log('got audio');
      evt.currentTarget.removeEventListener(
        'canplaythrough',
        this.handleAudioLoaded
      )
    }

    sendStepwiseEvent(type, obj) {
      this.stageRef.current.handleStepwiseEvent(type, obj)
      this.musicPlayer.current.handleStepwiseEvent(type, obj)
    }

    sendAppAuthorizationRequest() {
      this.setState({ activationCode: null })
      this.state.user.getIdToken().then((accessToken) => {
        var xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              var response = JSON.parse(xhr.responseText)
              if (response) {
                this.setState({ activationCode: response.activationCode })
              }
              console.log(xhr.responseText)
            } else {
              console.log('error authorizing')
            }
          }
        }
        xhr.open('POST', 'https://origin.step.works/appauthreq', true)
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
        xhr.send()
      })
    }

    handleCharactersEnabledToggle(evt) {
      let charactersEnabled = !this.state.charactersEnabled
      this.setState({ charactersEnabled: charactersEnabled })
    }

    handleSceneSelected(scene) {
      this.setState({
        editScene: scene,
        editSequence: scene.sequences[0],
        editStep: scene.sequences[0].steps[0]
      })
    }

    handleSequenceSelected(sequence) {
      this.setState({ editSequence: sequence, editStep: sequence.steps[0] })
    }

    handleStepSelected(step, character, execute = true) {
      // if we don't delay nextStep by a frame then React will batch the step state changes,
      // and then if there are multiple steps executed by the same character
      // in quick succession, only the last one will be registered
      if (this.props.mode === 'edit') {
        let action = null
        if (step.actions.indexOf(this.state.editAction) !== -1) {
          action = this.state.editAction
        } else {
          let contentfulActions = window.getContentfulActionsFromStep(step)
          if (contentfulActions.length > 0) {
            action = contentfulActions[0]
          } else if (step.actions.length > 0) {
            action = step.actions[0]
          }
        }
        if (character) {
          this.setState({
            editStep: step,
            editAction: action,
            editCharacter: character
          })
        } else if (action) {
          this.setState({
            editStep: step,
            editAction: action,
            editCharacter: action.targetCharacter
          })
        } else {
          this.setState({ editStep: step, editAction: null })
        }
        if (step) {
          this.state.editSequence.setCurrentStep(step)
          if (execute)
            setTimeout(() => {
              step.execute(true)
            }, 1)
        }
      }
    }

    handleActionSelected(action) {
      if (action) {
        this.setState({
          editAction: action,
          editCharacter: action ? action.targetCharacter : null
        })
      } else {
        this.setState({ editAction: action })
      }
      if (this.stateEditor.current) {
        this.stateEditor.current.forceUpdate()
      }
    }

    handlePanelSelected(id) {
      //this.state.stepwise.setInputEnabled(id === null);
      this.setState({ selectedPanelId: id })
    }

    handleCharacterSelected(character) {
      this.setState({ editCharacter: character })
    }

    handleLocationSelected(location) {
      this.setState({ editLocation: location })
      this.stageRef.current.setEditLocation(location)
    }

    handleMediaSelected(index) {
      let media = Object.values(this.state.stepwise.score.media)[index]
      this.setState({ editMedia: media })
    }

    updateDimensions() {
      this.setState({
        editorBodyStyles: { marginTop: window.innerHeight + 'px' }
      })
    }

    openDialog = (dialog) => {
      let state = { currentDialog: dialog }
      switch (dialog) {
        case 'confirm-save-alert':
        case 'confirm-delete-alert':
          this.setState({ storiesNeedUpdate: true })
          break

        case 'import-story':
          state.currentScript = ''
          break

        case 'export-story':
          state.currentScript = this.state.stepwise.serialize()
          break

        case 'stories':
          this.getStoriesForUser(this.state.user)
          break

        default:
          break
      }
      this.setState(state)
    }

    closeDialog = () => {
      switch (this.state.currentDialog) {
        case 'save-gist-alert':
          var select = document.getElementById('gist-select')
          if (select.value) {
            this.destinationGist = this.getGistForId(select.value)
          }
          break

        case 'scene-settings':
          this.musicPlayer.current.startMetronome()
          break

        default:
          break
      }
      this.setState({ currentDialog: 'none' })
    }

    duplicateSelectedStep = () => {
      let stepData = this.state.editStep.toJSON()
      let step = new Step(
        stepData,
        this.state.editStep.parentScore,
        this.state.editStep.parentSequence
      )
      this.state.editStep.parentSequence.addStepAfterStep(
        this.state.editStep,
        step
      )
      this.state.stepwise.score.setupReferences()
      this.setState({ editStep: step })
    }

    deleteSelectedStep = () => {
      this.state.editSequence.deleteStep(this.state.editStep)
      this.setState({ editStep: null })
      this.closeDialog()
    }

    // TODO: There's another version of this in StepColumn
    deleteSelectedAction = (evt) => {
      if (this.state.editAction.command === 'speak') {
        // clear out the text before deleting
        let editAction = this.state.editAction
        editAction.content = ''
        this.sendStepwiseEvent('action', editAction)
      }
      let index = this.state.editStep.actions.indexOf(this.state.editAction)
      let newAction
      if (this.state.editStep.actions.length > 1) {
        newAction = this.state.editStep.actions[Math.max(0, index - 1)]
      }
      this.state.editStep.deleteAction(this.state.editAction)
      this.handleActionSelected(newAction)
      if (this.columnEditorRef.current.state.currentColumn === 2) {
        this.columnEditorRef.current.scrollToColumn(1)
      }
      this.closeDialog()
    }

    deleteMedia(media) {
      this.state.stepwise.score.deleteMedia(media)
      this.setState({ editMedia: null })
      this.closeDialog()
    }

    getStory(storyId) {
      const db = firebase.firestore()
      var docRef = db.collection('stories').doc(storyId)
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.loadStoryJSON(JSON.parse(doc.data().story))
          }
        })
        .catch((error) => {
          console.log('Error getting story.')
        })
    }

    openGoogleSheet(evt) {
      var input = document.getElementById('input-sheets-url')
      var id = window.getSheetsIdFromUrl(input.value)
      if (id) {
        localStorage.stepworksStudioLastSheetsURL = input.value
        this.setState({ stepwise: null })
        new GoogleSheetsParser().getStepwiseJSONFromSheet(
          id,
          this.loadStoryFromStepwiseJSON,
          process.env.REACT_APP_GOOGLE_SHEETS_API_KEY
        )
        this.closeDialog()
      }
    }

    openTutorial() {
      window.open('https://youtu.be/gmjDRf6dHjU', '_blank')
    }

    openDocumentation() {
      window.open(
        'https://www.notion.so/Stepworks-Studio-Documentation-77ebf487335a475b9b913a8040d435b2',
        '_blank'
      )
    }

    /*addStep() {
    let stepData = {
      "states": [],
      "actions": []
    }
    let newStep = new Step(stepData, this.state.stepwise.score, this.state.editSequence);
    this.state.editSequence.addStepAfterStep(this.state.editStep, newStep);
    this.state.stepwise.score.setupReferences();
  }*/

    getAccountComponents() {
      var account
      if (this.state.user) {
        account = (
          <Popover
            className="user-account"
            content={
              <Menu>
                <MenuItem
                  text={'Sign out ' + this.state.user.displayName}
                  onClick={this.logout}
                />
              </Menu>
            }
            position={Position.BOTTOM}
          >
            <span>
              <img
                className="avatar"
                src={this.state.user.photoURL}
                alt="User avatar"
              />
            </span>
          </Popover>
        )
      } else {
        account = (
          <Button text="Sign in" onClick={() => this.openDialog('sign-in')} />
        )
      }
      return account
    }

    getNavbarCommands() {
      var commands
      if (this.canEdit()) {
        var fileMenu = (
          <Menu>
            <MenuItem
              text={text.navbar.file.new}
              onClick={this.newStory.bind(this)}
            />
            <Menu.Divider />
            <MenuItem
              text={text.navbar.file.open}
              onClick={() => this.openDialog('stories')}
            />
            {/*<MenuItem text="Open From Local Storage" onClick={() => this.openDialog('open-local-storage')}/>*/}
            {
              <MenuItem
                text={text.navbar.file.openSheets}
                onClick={() => this.openDialog('open-sheets')}
              />
            }
            <Menu.Divider />
            <MenuItem text={text.navbar.file.save} onClick={this.saveToCloud} />
            <MenuItem
              text={text.navbar.file.share}
              onClick={() => this.openDialog('share')}
              disabled={!this.state.currentStory}
            />
            <Menu.Divider />
            <MenuItem
              text={text.navbar.file.delete}
              onClick={() => this.openDialog('delete-story')}
              disabled={this.state.stories.length === 0}
            />
            {this.canExport() ? <Menu.Divider /> : null}
            {this.canExport() ? (
              <MenuItem
                text={text.navbar.file.importStory}
                onClick={() => this.openDialog('import-story')}
              />
            ) : null}
            {this.canExport() ? (
              <MenuItem
                text={text.navbar.file.exportStory}
                onClick={() => this.openDialog('export-story')}
              />
            ) : null}
            <MenuItem
              text={text.navbar.file.connectApp}
              onClick={() => this.openDialog('connect-app')}
            />
            {/*<Menu.Divider />
            <MenuItem
              icon="clean"
              text={
                !this.userIsSupporter()
                  ? text.navbar.file.upgrade
                  : text.navbar.file.benefits
              }
              onClick={() => this.openDialog('benefits')}
            />*/}
          </Menu>
        )
        var currentColumn = 0
        if (this.columnEditorRef.current) {
          currentColumn = this.columnEditorRef.current.state.currentColumn
        }
        var editMenu = (
          <Menu>
            <MenuItem
              text="Character Grid..."
              onClick={() => this.openDialog('distribute-panels')}
            />
            <MenuItem
              text="Duplicate Step"
              onClick={() => this.duplicateSelectedStep()}
              disabled={!this.state.editStep}
            />
            <MenuItem
              text="Delete Step..."
              onClick={() => this.openDialog('delete-step')}
              disabled={
                !this.state.editStep ||
                this.state.editSequence.steps.indexOf(this.state.editStep) === 0
              }
            />
            <MenuItem
              text="Delete Action..."
              onClick={() => this.openDialog('delete-action')}
              disabled={!this.state.editAction || currentColumn === 0}
            />
            <Menu.Divider />
            {this.state.stepwise ? (
              <MenuItem
                text="Story Settings..."
                onClick={() => this.openDialog('story-settings')}
              />
            ) : null}
            {this.state.stepwise ? (
              <MenuItem
                text="Scene Settings..."
                onClick={() => this.openDialog('scene-settings')}
              />
            ) : null}
            {this.state.stepwise ? (
              <MenuItem
                text="Sequence Settings..."
                onClick={() => this.openDialog('sequence-settings')}
              />
            ) : null}
            <MenuItem
              text="Media Library..."
              onClick={() => this.openDialog('media-library')}
            />
            {/*<MenuItem text="Toggle Stage Size Lock" onClick={this.toggleStageSizeLock.bind(this)}/>
        <MenuItem text="Add Step" onClick={this.addStep.bind(this)}/>*/}
          </Menu>
        )
        var helpMenu = (
          <Menu>
            <MenuItem text="Tutorial" onClick={this.openTutorial} />
            <MenuItem text="Documentation" onClick={this.openDocumentation} />
            <MenuItem
              text="Send Feedback..."
              onClick={() =>
                window.open('https://step.works/index.php/contact', '_blank')
              }
            />
          </Menu>
        )
        commands = (
          <Navbar.Group>
            <Button
              className="bp3-minimal image-btn"
              onClick={() => this.openDialog('about')}
            >
              <img
                className="logo"
                src="/images/stepworks-insignia-trans.png"
                alt="Stepworks Logo"
              />
            </Button>
            {process.env.REACT_APP_BRANDING !== 'tunesmap' ? (
              <Navbar.Divider />
            ) : null}
            <Popover
              className="user-account"
              content={fileMenu}
              position={Position.BOTTOM_LEFT}
              minimal={true}
            >
              <Button className="bp3-minimal" text="File" />
            </Popover>
            <Popover
              className="user-account"
              content={editMenu}
              position={Position.BOTTOM_LEFT}
              minimal={true}
            >
              <Button className="bp3-minimal" text="Edit" />
            </Popover>
            <Popover
              className="user-account"
              content={helpMenu}
              position={Position.BOTTOM_LEFT}
              minimal={true}
            >
              <Button className="bp3-minimal" text="Help" />
            </Popover>
            {/*<Popover className="user-account" content={toolsMenu} position={Position.BOTTOM_LEFT} minimal={true}>
          <Button className="bp3-minimal" text="Tools" />
        </Popover>*/}
          </Navbar.Group>
        )
      } else {
        commands = (
          <Navbar.Group>
            {/*<Button className="bp3-minimal image-btn" onClick={this.openDialogAbout}><img className="logo" src="/images/stepworks-insignia-trans.png" alt="Stepworks Logo"/></Button>*/}
            {process.env.REACT_APP_BRANDING !== 'tunesmap' ? (
              <img
                onClick={() => this.openDialog('about')}
                className="logo"
                src="/images/stepwise_insignia_red_lg@2x.png"
                alt="Stepworks Logo"
              />
            ) : null}
          </Navbar.Group>
        )
      }
      return commands
    }

    addNewMedia() {
      let mediaItem = new Media({
        id: uuidv4(),
        name: 'Untitled',
        type: Stepwise.FeatureTypes.IMAGE,
        source: '',
        width: '',
        height: ''
      })
      this.state.stepwise.score.addMedia(mediaItem)
      this.handleMediaSelected(
        Object.values(this.state.stepwise.score.media).length - 1
      )
    }

    handleImportScriptChange(evt) {
      this.setState({ currentScript: evt.target.value })
    }

    importStory() {
      this.loadStoryJSON(JSON.parse(this.state.currentScript))
      this.closeDialog()
    }

    getDialogImportStory() {
      var dialog = (
        <Dialog
          isOpen={this.state.currentDialog === 'import-story'}
          onClose={this.closeDialog}
          icon="import"
          title="Import Story"
        >
          <div className={Classes.DIALOG_BODY}>
            <TextArea
              className="large-text-area"
              onChange={this.handleImportScriptChange.bind(this)}
              value={this.state.currentScript}
              fill={true}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog}>Cancel</Button>
              <Button
                onClick={this.importStory.bind(this)}
                intent={Intent.PRIMARY}
              >
                Import
              </Button>
            </div>
          </div>
        </Dialog>
      )
      return dialog
    }

    getDialogExportStory() {
      var dialog = (
        <Dialog
          isOpen={this.state.currentDialog === 'export-story'}
          onClose={this.closeDialog}
          icon="export"
          title="Export Story"
        >
          <div className={Classes.DIALOG_BODY}>
            <p>
              Below is the script for the current story, which you can copy and
              paste to store elsewhere if you like.
            </p>
            <TextArea
              className="large-text-area"
              value={this.state.currentScript}
              fill={true}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog} intent={Intent.PRIMARY}>
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      )
      return dialog
    }

    distributePanels = (method) => {
      if (this.stageRef) {
        if (this.stageRef.current) {
          this.stageRef.current.distributePanels(method)
        }
      }
      this.closeDialog()
    }

    getDialogDistributePanels() {
      var dialog = (
        <Dialog
          isOpen={this.state.currentDialog === 'distribute-panels'}
          onClose={this.closeDialog}
          icon="grid-view"
          title="Character grid"
        >
          <div className={Classes.DIALOG_BODY}>
            <p>Arrange all characters as equally-sized panels in a grid.</p>
            <HTMLSelect
              id="distribute-color-select"
              options={this.distributeOptions}
              value={this.state.distributeOption}
              onChange={(evt) =>
                this.setState({ distributeOption: evt.currentTarget.value })
              }
              disabled={!this.state.editStep}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog}>Cancel</Button>
              <Button
                onClick={() =>
                  this.distributePanels(this.state.distributeOption)
                }
                intent={Intent.PRIMARY}
              >
                Arrange characters
              </Button>
            </div>
          </div>
        </Dialog>
      )
      return dialog
    }

    getDialogOpenFromGoogleSheets() {
      var lastSheetsURL = localStorage.stepworksStudioLastSheetsURL
      var dialog = (
        <Dialog
          isOpen={this.state.currentDialog === 'open-sheets'}
          onClose={this.closeDialog}
          icon="document"
          title="Open from Google Sheets"
        >
          <div className={Classes.DIALOG_BODY}>
            <InputGroup
              id="input-sheets-url"
              defaultValue={lastSheetsURL}
              leftIcon="document"
              placeholder="Enter Google Sheets URL"
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog}>Cancel</Button>
              <Button onClick={this.openGoogleSheet} intent={Intent.PRIMARY}>
                Open
              </Button>
            </div>
          </div>
        </Dialog>
      )
      return dialog
    }

    getDialogAbout() {
      return (
        <Dialog
          isOpen={this.state.currentDialog === 'about'}
          onClose={this.closeDialog}
          icon="application"
          title={text.about.title}
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="about-content">
              <p>
                <img
                  className="logo m-auto"
                  src="/images/stepwise_insignia_red_lg@2x.png"
                  alt={text.about.logoAlt}
                />
              </p>
              <h1>Stepworks Studio</h1>
              <p>© 2020-2025 by Erik Loyer</p>
              <p className="bp3-text-small">
                By using this app, you are indicating that you
                <br />
                accept the{' '}
                <a
                  href="https://step.works/index.php/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </a>{' '}
                and{' '}
                <a
                  href="https://step.works/index.php/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <p className="bp3-text-small">Version {this.version}</p>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog} intent={Intent.PRIMARY}>
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    getDialogMediaLibrary() {
      let mediaPreviews
      if (this.state.stepwise) {
        let mediaArr = Object.values(this.state.stepwise.score.media)
        mediaPreviews = mediaArr.map((media, index) => {
          let className = 'media-card'
          if (media === this.state.editMedia) {
            className += ' selected'
          }
          let url = media.thumbnail
          if (!url && media.type === 'image') {
            url = media.source
          }
          return (
            <div key={index} className={className}>
              <MediaPreview
                index={index}
                className="select-media-preview"
                type={media.type}
                source={url}
                onClick={this.handleMediaSelected}
              />
            </div>
          )
        })
      }
      return (
        <Dialog
          className="media-library"
          isOpen={this.state.currentDialog === 'media-library'}
          onClose={this.closeDialog}
          icon="media"
          title="Media Library"
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="media-editor">
              {/*<Tabs selectedTab="this-story">
            <Tab id="this-story" title="This Story"/>
            <Tab id="all-media" title="All Media"/>
          </Tabs>*/}
              <div className="media-table">
                {mediaPreviews}
                <div className="media-card">
                  <Button
                    className="media-preview-button"
                    icon="plus"
                    minimal={false}
                    onClick={this.addNewMedia.bind(this)}
                  ></Button>
                </div>
              </div>
              <div className="media-controls-wrapper">
                <MediaControls media={this.state.editMedia} />
              </div>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog} intent={Intent.PRIMARY}>
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    loadStoryForRow = (evt) => {
      var id = evt.currentTarget.getAttribute('data-story-id')
      this.setState({ currentStory: id })
      this.loadStoryJSON(JSON.parse(this.state.storiesById[id].story))
      this.closeDialog()
    }

    deleteStoryForRow = (evt) => {
      this.storyIdToDelete = evt.currentTarget.getAttribute('data-story-id')
      this.openDialog('delete-story-alert')
    }

    deleteStory = () => {
      const db = firebase.firestore()
      db.collection('stories')
        .doc(this.storyIdToDelete)
        .delete()
        .then(() => {
          this.openDialog('confirm-delete-alert')
          this.getStoriesForUser(this.state.user)
        })
        .catch((error) => {
          console.error('Error removing document: ', error)
        })
    }

    getStoryList(handleRowClick) {
      let stories = this.state.stories.map((data) => {
        var story = data.value
        var storyData = JSON.parse(story.story)
        return (
          <div
            key={data.key}
            className="story-row"
            data-story-id={data.key}
            onClick={handleRowClick}
          >
            <strong>{storyData.title}</strong>
            {storyData.primaryCredit.trim() !== ''
              ? ' ' + storyData.primaryCredit
              : ''}
          </div>
        )
      })
      return <div className="story-container">{stories}</div>
    }

    getDialogOpenStory() {
      return (
        <Dialog
          isOpen={this.state.currentDialog === 'stories'}
          onClose={this.closeDialog}
          icon="document"
          title="Open story"
        >
          <div className={Classes.DIALOG_BODY}>
            {this.getStoryList(this.loadStoryForRow)}
          </div>
        </Dialog>
      )
    }

    getDialogDeleteStory() {
      return (
        <Dialog
          isOpen={this.state.currentDialog === 'delete-story'}
          onClose={this.closeDialog}
          icon="trash"
          title="Delete story"
        >
          <div className={Classes.DIALOG_BODY}>
            <p>Select a story to delete.</p>
            {this.getStoryList(this.deleteStoryForRow)}
          </div>
        </Dialog>
      )
    }

    handleScorePropertyChange(evt, property, value) {
      let score = this.state.stepwise.score
      score[property] = value ? value : evt.currentTarget.value
    }

    getDialogSaveStory() {
      if (this.state.stepwise) {
        return (
          <Dialog
            isOpen={this.state.currentDialog === 'save-story'}
            onClose={this.closeDialog}
            icon="document"
            title="Save Story"
          >
            <div className={Classes.DIALOG_BODY}>
              <FormGroup label="Title" labelFor="title-input">
                <InputGroup
                  id="title-input"
                  placeholder="Enter story title"
                  value={this.state.stepwise.score.title}
                  onChange={(evt) =>
                    this.handleScorePropertyChange(
                      evt,
                      'title',
                      evt.target.value
                    )
                  }
                />
              </FormGroup>
              <FormGroup label="Primary credit" labelFor="primary-credit-input">
                <InputGroup
                  id="primary-credit-input"
                  placeholder="Enter primary credit"
                  value={this.state.stepwise.score.primaryCredit}
                  onChange={(evt) =>
                    this.handleScorePropertyChange(
                      evt,
                      'primaryCredit',
                      evt.target.value
                    )
                  }
                />
              </FormGroup>
              <FormGroup
                label="Secondary credit"
                labelFor="secondary-credit-input"
              >
                <InputGroup
                  id="secondary-credit-input"
                  placeholder="Enter secondary credit"
                  value={this.state.stepwise.score.secondaryCredit}
                  onChange={(evt) =>
                    this.handleScorePropertyChange(
                      evt,
                      'secondaryCredit',
                      evt.target.value
                    )
                  }
                />
              </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={this.closeDialog}>Cancel</Button>
                <Button
                  onClick={this.saveNewStoryToCloud}
                  intent={Intent.PRIMARY}
                >
                  Save
                </Button>
              </div>
            </div>
          </Dialog>
        )
      } else {
        return null
      }
    }

    getDialogShare() {
      let url = window.location.origin + '?story=' + this.state.currentStory
      return (
        <Dialog
          isOpen={this.state.currentDialog === 'share'}
          onClose={this.closeDialog}
          icon="document"
          title="Share"
        >
          <div className={Classes.DIALOG_BODY}>
            <p>You can share this story with others using this URL:</p>
            <InputGroup leftIcon="document" value={url} readOnly={true} />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog} intent={Intent.PRIMARY}>
                Done
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    getDialogTutorial() {
      return (
        <Dialog
          isOpen={this.state.currentDialog === 'tutorial'}
          onClose={this.closeDialog}
          icon="hand"
          title="Welcome"
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="about-content">
              <p>
                <img
                  className="logo m-auto"
                  src="/images/stepwise_insignia_red_lg@2x.png"
                  alt="Stepworks Logo"
                />
              </p>
              <h1>Welcome to Stepworks Studio!</h1>
            </div>
            <p>
              Here are some resources to help you get started (they’re also in
              the Help menu if you need them later).
            </p>
            <br />
            <div className="card-columns">
              <Card interactive={true} elevation={Elevation.TWO}>
                <h2>Tutorial</h2>
                <p>Learn the basics of creating stories.</p>
                <Button intent={Intent.PRIMARY} onClick={this.openTutorial}>
                  Watch video
                </Button>
              </Card>
              <Card interactive={true} elevation={Elevation.TWO}>
                <h2>Documentation</h2>
                <p>Details about using Stepworks Studio.</p>
                <Button
                  intent={Intent.PRIMARY}
                  onClick={this.openDocumentation}
                >
                  Open documentation
                </Button>
              </Card>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={this.closeDialog} large={true}>
                Continue to Stepworks Studio
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    getDialogConnectApp() {
      return (
        <Dialog
          isOpen={this.state.currentDialog === 'connect-app'}
          onClose={this.closeDialog}
          icon="resolve"
          title="Connect to App"
          className="connect-dialog bp3-dark"
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="text-center about-content mt-4 mb-8">
              <p>
                <img
                  className="logo m-auto"
                  src="/images/stepwise_insignia_red_lg@2x.png"
                  alt={text.about.logoAlt}
                />
              </p>
              <h1>{text.connect.title}</h1>
            </div>
            <p className="bp3-text-large">{text.connect.otherApps}</p>
            {/*this.userIsSupporter() ? (
              <p className="bp3-text-large">{text.connect.haveAppRewards}</p>
            ) : (
              <p className="bp3-text-large">{text.connect.wantAppRewards}</p>
            )*/}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className="flex justify-center gap-2">
              {/*this.userIsSupporter() ? (
                <Button onClick={this.openBenefitsPage} intent={Intent.PRIMARY}>
                  {text.connect.goToPatreon}
                </Button>
              ) : null*/}
              {/*!this.userIsSupporter() ? (
                <Button onClick={this.requestPatreonLink}>
                  {text.connect.linkPatreon}
                </Button>
              ) : null*/}
              {/*!this.userIsSupporter() ? (
                <Button
                  onClick={this.openMembershipPage}
                  intent={Intent.PRIMARY}
                >
                  {text.connect.becomeSupporter}
                </Button>
              ) : null*/}
              <Button
                onClick={this.openAppInfo}
                intent={Intent.PRIMARY}
              >
                {text.connect.goToItch}
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    /* this method is duplicated in GetCode.js */
    requestPatreonLink = () => {
      console.log('request patreon link')
      var params = 'response_type=code'
      params += '&client_id=' + process.env.REACT_APP_PATREON_CLIENT_ID
      params +=
        '&redirect_uri=' +
        encodeURIComponent('https://origin.step.works/patreon_redirect')
      params += '&scope=identity identity.memberships'
      if (this.state.user) params += '&state=' + this.state.user.uid
      var url = 'https://www.patreon.com/oauth2/authorize?' + params
      window.open(url)
    }

    /* this method is duplicated in GetCode.js */
    openBenefitsPage = () => {
      window.open('https://www.patreon.com/opertoon', '_blank')
    }

    openMembershipPage = () => {
      window.open('https://www.patreon.com/opertoon/membership', '_blank')
    }

    openAppInfo = () => {
      window.open('https://opertoon.itch.io', '_blank')
    }

    getAlertDeleteStory() {
      return (
        <Alert
          isOpen={this.state.currentDialog === 'delete-story-alert'}
          cancelButtonText="Cancel"
          confirmButtonText="Delete story"
          intent={Intent.DANGER}
          icon="trash"
          onClose={this.closeDialog}
          onConfirm={() => this.deleteStory(this.storyIdToDelete)}
        >
          <p>
            Are you sure you want to delete this story? This cannot be undone.
          </p>
        </Alert>
      )
    }

    getAlertDeleteMedia() {
      return (
        <Alert
          isOpen={this.state.currentDialog === 'delete-media'}
          cancelButtonText="Cancel"
          confirmButtonText="Delete media"
          intent={Intent.DANGER}
          icon="trash"
          onClose={this.closeDialog}
          onConfirm={() => this.deleteMedia(this.state.editMedia)}
        >
          <p>
            Are you sure you want to delete this media item? This cannot be
            undone.
          </p>
        </Alert>
      )
    }

    getAlertDeleteStep() {
      return (
        <Alert
          isOpen={this.state.currentDialog === 'delete-step'}
          cancelButtonText="Cancel"
          confirmButtonText="Delete step"
          intent={Intent.DANGER}
          icon="trash"
          onClose={this.closeDialog}
          onConfirm={() => this.deleteSelectedStep()}
        >
          <p>
            Are you sure you want to delete this step? This cannot be undone.
          </p>
        </Alert>
      )
    }

    getAlertDeleteAction() {
      return (
        <Alert
          isOpen={this.state.currentDialog === 'delete-action'}
          cancelButtonText="Cancel"
          confirmButtonText="Delete action"
          intent={Intent.DANGER}
          icon="trash"
          onClose={this.closeDialog}
          onConfirm={() => this.deleteSelectedAction()}
        >
          <p>
            Are you sure you want to delete this action? This cannot be undone.
          </p>
        </Alert>
      )
    }

    getAlertPaywall() {
      let paywallAlerts = ['story-quantity-alert']
      let isOpen = paywallAlerts.indexOf(this.state.currentDialog) !== -1
      let message = ''
      switch (this.state.currentDialog) {
        case 'story-quantity-alert':
          message = (
            <p>
              Your account only supports {this.state.maxStories} saved stories.
              Please delete a story before saving (you can use the Export Story
              feature to save its data first).
            </p>
          )
          break
        default:
          break
      }
      return (
        <Alert
          isOpen={isOpen}
          confirmButtonText="OK"
          intent={Intent.WARNING}
          icon="warning-sign"
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          onClose={this.closeDialog}
          onConfirm={this.closeDialog}
          onOpened={(elem) =>
            this.focusConfirmButton(elem, 'bp3-intent-warning')
          }
        >
          {message}
        </Alert>
      )
    }

    getAlertConfirm() {
      let dialogs = ['confirm-save-alert', 'confirm-delete-alert']
      let isOpen = dialogs.indexOf(this.state.currentDialog) !== -1
      let message = ''
      switch (this.state.currentDialog) {
        case 'confirm-save-alert':
          message =
            'Your story “' + this.state.stepwise.score.title + '” was saved.'
          break
        case 'confirm-delete-alert':
          message = 'Your story was deleted.'
          break
        default:
          break
      }
      return (
        <Alert
          isOpen={isOpen}
          confirmButtonText="OK"
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          onClose={this.closeDialog}
        >
          <p>{message}</p>
        </Alert>
      )
    }

    focusConfirmButton(element, className) {
      let button = element.querySelector('button.' + className)
      if (button) button.focus()
    }

    handleTabChange(newTabId, prevTabId, evt) {
      this.setState({ currentView: newTabId })
    }

    togglePreview(evt) {
      let isPreviewing = !this.state.isPreviewing
      this.setPreview(isPreviewing)
      if (!isPreviewing) {
        this.stageRef.current.pause()
        this.state.stepwise.score.stop()
        let sequence = this.state.stepwise.score.currentScene.currentSequence
        let step = sequence.steps[Math.max(0, sequence.stepIndex)]
        step.execute(true)
        //this.handleStepSelected(step);
        this.stageRef.current.updateLayout()
      } else {
        let shiftDown = false
        if (evt) {
          shiftDown = evt.nativeEvent.shiftKey
        }
        let sequence = this.state.stepwise.score.currentScene.currentSequence
        if (!shiftDown) {
          this.state.stepwise.score.reset()
          this.resetLayout()
          this.resetContent()
          this.stageRef.current.doUpdate()
        } else {
          sequence.stepIndex = Math.max(-1, sequence.stepIndex - 1)
        }
        this.stageRef.current.play()
      }
      this.handleStoryUpdate()
    }

    toggleExpand() {
      let expanded = !this.state.expanded
      if (expanded) {
        let character = this.state.editCharacter
        if (!character) {
          character = Object.values(this.state.stepwise.score.characters)[0]
        }
        this.handleCharacterSelected(character)
      }
      this.setState({ expanded: expanded })
    }

    setPreview(isPreviewing, showWelcome = false) {
      let messageVisible = false
      let welcomeVisible = false
      if (isPreviewing) {
        let scene = Object.values(this.state.stepwise.score.scenes)[0]
        this.state.stepwise.score.setScene(scene)
        scene.setSequence(scene.defaultSequence)
        if (this.state.stepwise) {
          this.state.stepwise.inputManager.setEnabled(!showWelcome)
        }
      } else {
        if (this.state.stepwise) {
          this.state.stepwise.inputManager.setEnabled(false)
        }
      }
      if (showWelcome) {
        welcomeVisible = true
        messageVisible = false
      } else {
        welcomeVisible = false
        messageVisible = true
      }
      this.setState({
        isPreviewing: isPreviewing,
        welcomeVisible: welcomeVisible,
        messageVisible: messageVisible
      })
    }

    handleStoryUpdate = (isEmpty = false) => {
      if (this.stageRef.current) {
        this.stageRef.current.handleStoryUpdate()
      }
    }

    resetLayout() {
      if (this.stageRef.current) {
        this.stageRef.current.resetLayout()
      }
    }

    resetContent() {
      if (this.stageRef.current) {
        this.stageRef.current.resetContent()
      }
    }

    handleWelcomeClick() {
      this.setState({ welcomeVisible: false, messageVisible: true })
      let sequence = this.state.stepwise.score.currentScene.currentSequence
      sequence.reset()
      this.resetLayout()
      this.state.stepwise.inputManager.setEnabled(true)
    }

    handleMessageClick() {
      this.state.stepwise.nextStep()
    }

    /*toggleStageSizeLock() {
    if (this.state.stageDimensions) {
      this.setState({stageDimensions: null});
    } else {
      this.setState({stageDimensions: {width: this.state.stepwise.score.stageWidth * .5, height: this.state.stepwise.score.stageHeight * .5}});
    }
  }*/

    updateStage() {
      if (this.stageRef.current) {
        this.stageRef.current.doUpdate()
      }
    }

    getEZCreate() {
      return (
        <EZCreateDialog
          user={this.state.user}
          stepwise={this.state.stepwise}
          step={this.state.editStep}
          character={this.state.editCharacter}
          onStepChange={this.handleStepSelected}
          onStoryUpdate={this.handleStoryUpdate}
        />
      )
    }

    getSplash() {
      if (this.state.welcomeVisible) {
        let presentedBy
        let product = 'Stepworks Studio'
        let productDesc = (
          <div>
            <p>
              <strong>Stepworks Studio</strong> is a new way to create
              media-rich compositions for live, pre-recorded, and interactive
              performance.
            </p>
            <p>
              <strong>Click below to get started!</strong>
            </p>
          </div>
        )
        let versionDesc = 'Version'
        if (process.env.REACT_APP_BRANDING === 'tunesmap') {
          product = 'TunesMap.tv'
          productDesc = (
            <div>
              <p>
                <strong>TunesMap.tv</strong> enhances live streams with rich
                cultural context. Find out what we can do for your streams —{' '}
                <a href="https://www.tunesmap.com/info-contact">
                  get in touch today
                </a>
                .
              </p>
              <p>Content creators, click below to sign in.</p>
            </div>
          )
          presentedBy = 'Presented by TunesMap.tv'
          versionDesc = 'Powered by Stepworks Studio'
        }

        let classes = 'splash-overlay'

        let title = ''
        let primaryCredit, secondaryCredit
        let duration
        if (this.gotStoryFromUrl) {
          title = 'Welcome to this Stepworks presentation.'
          if (this.state.stepwise) {
            title = (
              <span className="title">{this.state.stepwise.score.title}</span>
            )
            if (this.state.stepwise.score.primaryCredit) {
              primaryCredit = (
                <p className="credit">
                  {this.state.stepwise.score.primaryCredit}
                </p>
              )
            }
            if (this.state.stepwise.score.secondaryCredit) {
              secondaryCredit = (
                <p className="aux-text">
                  {this.state.stepwise.score.secondaryCredit}
                </p>
              )
            }
            if (this.state.stepwise.score.durationDescription) {
              duration = (
                <p className="aux-text">
                  Duration: {this.state.stepwise.score.durationDescription}
                </p>
              )
            }
          }
          return (
            <Overlay isOpen={true}>
              <div className={classes}>
                <H1>{title}</H1>
                {primaryCredit}
                {secondaryCredit}
                {presentedBy ? <p className="credit">{presentedBy}</p> : null}
                {duration}
                <br />
                {!isMobile ? (
                  <div>
                    <p>
                      <strong>
                        Press the → key or click to advance at your own pace.
                      </strong>
                    </p>
                    <p>
                      If you’re using a keyboard, the letter and number keys
                      will also advance.
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      <strong>Tap to advance at your own pace.</strong>
                    </p>
                    <p>You may need to unmute your device to hear audio.</p>
                  </div>
                )}
                <br />
                <div className={'footer ' + Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    className="app-launch"
                    fill={true}
                    large={true}
                    onClick={this.handleWelcomeClick.bind(this)}
                    intent={Intent.SUCCESS}
                  >
                    <strong>Begin</strong>
                  </Button>
                </div>
                <br />
                <p className="aux-text">
                  Powered by <a href="https://step.works">Stepworks Studio</a>{' '}
                  {this.version}
                </p>
              </div>
            </Overlay>
          )
        } else {
          title = 'Stepworks Studio'
          return (
            <Overlay isOpen={true} className="opener">
              <div className={classes}>
                <H1>
                  <span className="title">Welcome to {product}.</span>
                </H1>
                <br />
                {productDesc}
                <br />
                <div className={'footer ' + Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    className="app-launch"
                    fill={true}
                    large={true}
                    onClick={this.openEditor}
                    intent={Intent.SUCCESS}
                  >
                    Launch Stepworks Studio
                  </Button>
                </div>
                <br />
                <p className="aux-text">
                  {versionDesc} {this.version}
                </p>
              </div>
            </Overlay>
          )
        }
      } else {
        return null
      }
    }

    canEdit() {
      return this.props.mode === 'edit' && this.state.user
    }

    canExport() {
      return this.props.mode === 'edit' && this.state.user
    }

    toggleSidebar() {
      let sidebarVisible = !this.state.sidebarVisible
      this.setState({ sidebarVisible: sidebarVisible })
    }

    getMessage() {
      if (!isMobile) {
        return (
          <div className="message" onClick={this.handleMessageClick.bind(this)}>
            <h1>Press → to advance</h1>
          </div>
        )
      } else {
        return (
          <div className="message" onClick={this.handleMessageClick.bind(this)}>
            <h1>Tap to advance</h1>
          </div>
        )
      }
    }

    getPanelLayoutForCharacter = (character) => {
      return this.stageRef.current.getPanelLayoutForCharacter(character)
    }

    render() {
      /*if (this.isAuthenticated && this.state.user === null) {
      this.getUser();
    }*/

      //var overviewVisible = this.state.currentView === 'tab-overview';
      var previewVisible = this.state.currentView === 'tab-preview'
      /*var sequenceBar = <SequenceBar
      stepwise={this.state.stepwise}
      scene={this.state.editScene}
      sequence={this.state.editSequence}
      character={this.state.editCharacter}
      step={this.state.editStep}
      expanded={this.state.expanded}
      sidebarVisible={this.state.sidebarVisible}
      onStoryUpdate={this.handleStoryUpdate}
      onSceneChange={this.handleSceneSelected}
      onSequenceChange={this.handleSequenceSelected}
      onCharacterChange={this.handleCharacterSelected}
      onStepChange={this.handleStepSelected}
      onSidebarToggle={this.toggleSidebar.bind(this)}
      onExpandToggle={this.toggleExpand.bind(this)}
      onStageUpdateNeeded={this.updateStage.bind(this)}
    />*/

      let mainColClass = 'main-view-col'
      if (this.state.sidebarVisible && !this.state.isPreviewing) {
        mainColClass = 'main-view-col reduced'
      }

      let stageContent

      let stageWrapperClass = 'stage-wrapper'
      let stageWrapperStyle = {}
      let mainColStyle = {}
      if (this.state.stepwise) {
        if (this.state.stepwise.score.enforceStageSize) {
          stageWrapperClass += ' locked'
          let targetWidth = this.state.stepwise.score.stageWidth * 0.5 + 2
          let targetHeight = this.state.stepwise.score.stageHeight * 0.5 + 2
          stageWrapperStyle = {
            width: targetWidth,
            height: targetHeight
          }
          if (this.state.isPreviewing) {
            let targetScale = 1
            if (window.innerWidth < targetWidth) {
              targetScale = window.innerWidth / parseFloat(targetWidth)
              stageWrapperStyle.transform = 'scale(' + targetScale + ')'
              stageWrapperStyle.transformOrigin = '0% 0%'
              stageWrapperStyle.overflow = 'visible'
              mainColStyle.width = targetWidth * targetScale + 'px'
            } else {
              stageWrapperStyle.margin = 'auto'
              //mainColStyle.width = '100%';
              mainColStyle.height = '100%'
            }
            if (window.innerHeight > targetHeight * targetScale) {
              stageWrapperStyle.marginTop =
                (window.innerHeight - targetHeight * targetScale) * 0.5 + 'px'
            }
          }
        } else {
          //mainColStyle.width = '100%';
          mainColStyle.display = 'flex'
          mainColStyle.flexDirection = 'column'
          stageWrapperStyle = {
            width: '100%',
            height: '100%'
          }
        }
      }
      if (this.state.welcomeVisible) {
        stageWrapperClass += ' invisible'
      }

      if (
        this.state.user /*&& this.state.userWasInvited === 'yes'*/ &&
        this.props.mode === 'edit'
      ) {
        stageContent = (
          <div className="stage-row">
            {/*!this.state.isPreviewing && this.canEdit() ? <TimelineEditor
          stepwise={this.state.stepwise}
          sequence={this.state.editSequence}
          onStepChange={this.handleStepSelected}
          onActionChange={this.handleActionSelected}
          onStepwiseEvent={this.sendStepwiseEvent.bind(this)}
          onStoryUpdate={this.handleStoryUpdate}
          musicPlayer={this.musicPlayer}
        /> : null*/}
            {/*!this.state.isPreviewing && this.canEdit() ? <GridEditor
          stepwise={this.state.stepwise}
          sequence={this.state.editSequence}
          onStepChange={this.handleStepSelected}
          onActionChange={this.handleActionSelected}
          onStepwiseEvent={this.sendStepwiseEvent.bind(this)}
          onStoryUpdate={this.handleStoryUpdate}
          musicPlayer={this.musicPlayer}
        /> : null(*/}
            {this.canEdit() ? (
              <ColumnEditor
                ref={this.columnEditorRef}
                visible={!this.state.isPreviewing}
                stage={this.stageRef}
                stepwise={this.state.stepwise}
                sequence={this.state.editSequence}
                user={this.state.user}
                editStep={this.state.editStep}
                editAction={this.state.editAction}
                editCharacter={this.state.editCharacter}
                uploadedMedia={this.state.uploadedMedia}
                uploadedMediaSize={this.state.uploadedMediaSize}
                maxUploadedMedia={this.state.maxUploadedMedia}
                onMediaUploaded={this.getMediaForUser}
                characterDialog={this.characterDialogRef}
                charactersEnabled={this.state.charactersEnabled}
                onCharactersEnabledToggle={this.handleCharactersEnabledToggle.bind(
                  this
                )}
                onStepChange={this.handleStepSelected}
                onActionChange={this.handleActionSelected}
                onCharacterChange={this.handleCharacterSelected}
                onSequenceChange={this.handleSequenceSelected}
                onStepwiseEvent={this.sendStepwiseEvent.bind(this)}
                onStoryUpdate={this.handleStoryUpdate}
                onPanelLayoutNeeded={this.getPanelLayoutForCharacter}
                musicPlayer={this.musicPlayer}
              />
            ) : null}
            <div className={mainColClass} style={mainColStyle}>
              {/* RECENT !this.state.isPreviewing && this.canEdit() ? sequenceBar : null */}
              {!this.state.isPreviewing && this.canEdit() ? (
                <StateEditor
                  ref={this.stateEditor}
                  stepwise={this.state.stepwise}
                  sequence={this.state.editSequence}
                  editStep={this.state.editStep}
                  editAction={this.state.editAction}
                  editCharacter={this.state.editCharacter}
                  characterDialog={this.characterDialogRef}
                  charactersEnabled={this.state.charactersEnabled}
                  musicPlayer={this.musicPlayer}
                  onStepChange={this.handleStepSelected}
                  onActionChange={this.handleActionSelected}
                  onCharacterChange={this.handleCharacterSelected}
                  onPanelLayoutNeeded={this.getPanelLayoutForCharacter}
                  onStepwiseEvent={this.sendStepwiseEvent}
                  onStoryUpdate={this.handleStoryUpdate}
                />
              ) : null}
              <div className={stageWrapperClass} style={stageWrapperStyle}>
                <Stage
                  ref={this.stageRef}
                  visible={previewVisible}
                  stepwise={this.state.stepwise}
                  sequence={this.state.editSequence}
                  charactersEnabled={this.state.charactersEnabled}
                  charactersToIgnore={this.state.charactersToIgnore}
                  editStep={this.state.editStep}
                  editAction={this.state.editAction}
                  editCharacter={this.state.editCharacter}
                  onPanelSelect={this.handlePanelSelected}
                  onStepChange={this.handleStepSelected}
                  onActionChange={this.handleActionSelected}
                  onCharacterChange={this.handleCharacterSelected}
                  selectedPanelId={this.state.selectedPanelId}
                  onCameraChange={this.handleCameraSelected}
                  onStepwiseEvent={this.sendStepwiseEvent}
                  isEditing={true}
                  isPreviewing={this.state.isPreviewing}
                />
              </div>
              {/*!this.state.isPreviewing && this.canEdit() ? this.getEZCreate() : null*/}
            </div>
          </div>
        )
      } else {
        stageContent = (
          <div className="stage-row">
            <div className={mainColClass} style={mainColStyle}>
              <div className={stageWrapperClass} style={stageWrapperStyle}>
                <Stage
                  ref={this.stageRef}
                  visible={previewVisible}
                  stepwise={this.state.stepwise}
                  sequence={this.state.editSequence}
                  charactersEnabled={this.state.charactersEnabled}
                  charactersToIgnore={this.state.charactersToIgnore}
                  editStep={this.state.editStep}
                  editAction={this.state.editAction}
                  editCharacter={this.state.editCharacter}
                  onPanelSelect={this.handlePanelSelected}
                  onStepChange={this.handleStepSelected}
                  onActionChange={this.handleActionSelected}
                  onCharacterChange={this.handleCharacterSelected}
                  selectedPanelId={this.state.selectedPanelId}
                  onCameraChange={this.handleCameraSelected}
                  onStepwiseEvent={this.sendStepwiseEvent}
                  isEditing={false}
                  isPreviewing={this.state.isPreviewing}
                />
              </div>
            </div>
          </div>
        )
      }

      let privilegesMsg = ''
      if (
        this.state.user &&
        !this.canEdit() &&
        this.state.permissions !== 'unknown'
      ) {
        privilegesMsg = 'You do not have editing privileges.'
      }

      return (
        <div className="App">
          <div className="app-column">
            <div
              className={
                !this.state.isPreviewing && this.props.mode === 'edit'
                  ? 'menu-bar'
                  : 'menu-bar hidden'
              }
            >
              <Navbar>
                {this.getNavbarCommands()}
                <Navbar.Group align={Alignment.RIGHT}>
                  <strong style={{ color: '#8A9BA8' }}>BETA</strong>
                  &nbsp;&nbsp;&nbsp;
                  {this.canEdit() ? (
                    <Button
                      icon={this.state.isPreviewing ? 'stop' : 'play'}
                      intent={this.state.isPreviewing ? 'none' : 'success'}
                      onClick={this.togglePreview}
                    />
                  ) : null}
                  {this.state.user /*&& this.state.userWasInvited === 'yes'*/ &&
                  !this.canEdit() ? (
                    <div>{privilegesMsg}</div>
                  ) : null}
                  <Navbar.Divider />
                  {this.getAccountComponents()}
                </Navbar.Group>
              </Navbar>
            </div>
            {stageContent}
            {/*this.getDialogOpenFromGist()}
          {this.getDialogSaveToGist()*/}
            {this.getDialogOpenFromGoogleSheets()}
            {this.getDialogAbout()}
            {this.getDialogMediaLibrary()}
            {this.getDialogImportStory()}
            {this.getDialogExportStory()}
            {this.getDialogDistributePanels()}
            {this.getDialogOpenStory()}
            {this.getDialogDeleteStory()}
            {this.getDialogSaveStory()}
            {this.getDialogShare()}
            {this.getDialogTutorial()}
            {this.getDialogConnectApp()}
            {/*<BenefitsDialog
              isOpen={this.state.currentDialog === 'benefits'}
              onClose={this.closeDialog}
              onRequestPatreonLink={this.requestPatreonLink}
              onOpenBenefitsPage={this.openBenefitsPage}
              patreonAccountLinked={this.state.patreonAccountLinked}
              benefits={this.state.benefits}
              uploadedMediaSize={this.state.uploadedMediaSize}
              maxUploadedMedia={this.state.maxUploadedMedia}
              uploadedStoryCount={this.state.stories.length}
              maxStories={this.state.maxStories}
            />*/}
            {/*this.getAlertOpenLocalStorage()}
          {this.getAlertSaveLocalStorage()}
          {this.getAlertSaveGist()*/}
            {this.getAlertConfirm()}
            {this.state.stepwise ? (
              <StorySettingsDialog
                isOpen={this.state.currentDialog === 'story-settings'}
                onClose={this.closeDialog}
                score={this.state.stepwise.score}
              />
            ) : null}
            {this.state.stepwise ? (
              <SceneSettingsDialog
                isOpen={this.state.currentDialog === 'scene-settings'}
                onClose={this.closeDialog}
                scene={Object.values(this.state.stepwise.score.scenes)[0]}
              />
            ) : null}
            {this.state.stepwise ? (
              <SequenceSettingsDialog
                isOpen={this.state.currentDialog === 'sequence-settings'}
                onClose={this.closeDialog}
                sequence={this.state.editSequence}
                benefits={this.state.benefits}
                user={this.state.user}
              />
            ) : null}
            <CharacterDialog
              ref={this.characterDialogRef}
              musicPlayer={this.musicPlayer}
              stepwise={this.state.stepwise}
              sequence={this.state.editSequence}
              editStep={this.state.editStep}
              onStoryUpdate={this.handleStoryUpdate}
              onStepChange={this.handleStepSelected}
              onActionChange={this.handleActionSelected}
            />
            {this.getAlertDeleteMedia()}
            {this.getAlertDeleteStep()}
            {this.getAlertDeleteAction()}
            {this.getAlertDeleteStory()}
            {this.getAlertPaywall()}
            {this.getSplash()}
            <Dialog
              isOpen={this.state.currentDialog === 'sign-in'}
              onOpened={this.showSignInUI}
              title="Sign in"
              canEscapeKeyClose={false}
              canOutsideClickClose={false}
              isCloseButtonShown={false}
            >
              <div className={Classes.DIALOG_BODY}>
                <div className="about-content">
                  <p>
                    <img
                      className="logo m-auto"
                      src="/images/stepwise_insignia_red_lg@2x.png"
                      alt="Stepworks Logo"
                    />
                  </p>
                  <h1>Stepworks Studio</h1>
                  <p className="bp3-text-small">Version {this.version}</p>
                </div>
                <div id="firebaseui-auth-container"></div>
                <div id="loader">Loading...</div>
              </div>
            </Dialog>
            <MusicPlayer
              ref={this.musicPlayer}
              stepwise={this.state.stepwise}
              canPlayWhileEditing={
                this.columnEditorRef.current
                  ? this.columnEditorRef.current.state.currentColumn > 0
                  : false
              }
              isPreviewing={this.state.isPreviewing}
            />
            {this.state.messageVisible && this.state.isPreviewing
              ? this.getMessage()
              : null}
          </div>
        </div>
      )
    }
  }
)

export default App
